import { ENVIRONMENT } from "./EnvConfig";

export const API_URL = "https://stoplight.io/mocks/brightspeed-apiverse/";
export const SMARTY_STREET_ADDRESS_URL =
  "https://us-autocomplete-pro.api.smartystreets.com/lookup?key=117559863585036761&license=us-autocomplete-pro-cloud&search=";
export const LOCALHOST_SMARTY_STREET_ADDRESS_URL =
  "https://us-autocomplete-pro.api.smartystreets.com/lookup?auth-id=075fdb94-1a3c-8165-b16f-d8428e11428e&auth-token=93d2qjdBKGgCLF3klTlV&search=";
export const VALIDATE_ADDRESSS_GET_LAT_LOG =
  "https://us-street.api.smartystreets.com";
export const FB_FIELDS = "id,email,name,first_name,middle_name,last_name";
export const ACCOUNT_NUMBER = "A00004763";
export const INVOICE_NUMBER = "NV00000849";
export const ACCOUNT_KEY = "8ac698a881cda5d60181d045bc4753d9";
export const ACCOUNT_MANAGE_ID = "77611723";

export const API_KEY = "bltae235e99a6dd6f85";
export const API_KEY_PROD = "blt63e9a2c6573b53ee";
export const ACCESS_TOKEN = "cs3417308ce78752a550fd6143";
export const ACCESS_TOKEN_PROD = "cs9d381ae779fc9019169c749c";
export const CHANNEL_ID = "mobile-acm";

export const PAYMENT_IFO_ID = "79847062";
const IAM_GET_USER_DETAILS = `?$select=userPrincipalName&$filter=mail%20eq%20'EMAIL' AND ${
  isProd()
    ? "extension_42054e1351c040b08b185b58c192f49e_pwResetEmailId"
    : isUAT()
    ? "extension_6645a143948b4441a17cc417cbbc0567_pwResetEmailId"
    : "extension_a5021af605b141d78e63d3338ade2884_pwResetEmailId"
} eq 'ACCID'`;

function isProd() {
  return (
    process.env.REACT_APP_ENV === "production" ||
    process.env.NODE_ENV === ENVIRONMENT.production
  );
}

function isUAT() {
  return (
    (process.env.REACT_APP_ENV === "uat" ||
    process.env.NODE_ENV === ENVIRONMENT.uat)
  );
}

export const ACCOUNT_CONTENT = {
  page: "account_settings_page",
  key: isProd() ? "blt4be7bded6a4c64fd" : "blt8894c30e6af6cf57",
};
export const HELP_SUPPORT_CONTENT = {
  page: "help_and_support_content",
  key: isProd() ? "blt9b1bbae3acc05448" : "blt3ba11b02507b7615",
};
export const VACATION_PAUSE_SERVICE = {
  page: "vacation_pause_service",
  key: isProd() ? "bltd3e22bf0d010aa7a" : "blt6eb2ad80da6a4ed9",
};
export const NON_PAY_SUSPEND = {
  page: "non_pay_suspend",
  key: isProd() ? "blt529f1450105402bb" : "blt4e104463d48abbfa",
};
export const CHANGE_PLAN = {
  page: "change_my_plan",
  key: isProd() ? "bltd05c82f28c9e43d5" : "blt449b691704b95b0f",
};
export const HOME_CONTENT = {
  page: "account_management_home_page",
  key: isProd() ? "blt4a5200fbf350e9af" : "blt71a0570d2338effd",
};
export const APPLY_ACP_MODAL_CONTENT = {
  page: "account_management_apply_acp_modal",
  key: isProd() ? "bltce4548c7ac94df74" : "bltd944f0cccb9be21b",
};
export const ACP_FORM_CONTENT = {
  page: "account_management_acp_signup",
  key: isProd() ? "blteb3d92aa0ba6bba5" : "blt4b34ec7fb4070f10",
};
export const PAYMENT_CONTENT = {
  page: "payments_page",
  key: isProd() ? "bltdec64e72836e0a31" : "blt6587e18a21a64c3e",
};
export const APPOINTMENT_CONTENT = {
  page: "appointment_page",
  key: isProd() ? "blt1270efbc9ca0b15b" : "blta3a865a924a6849c",
};
export const LOGIN_CONTENT = {
  page: "login_content",
  key: isProd() ? "blt040d63a05165d301" : "blt6c30266b823f44a7",
};
export const LOGIN_HEADER_CONTENT = {
  page: "login_header_content",
  key: isProd() ? "blt924fde0c54ebb041" : "bltc2bc0630347993e8",
};

export const NEW_LOGIN_HEADER_CONTENT = {
  page: "am_login_header",
  key: isProd() ? "blteffc804436ba874d" : "bltad5cb3179f224550",
};

export const FOOTER_CONTENT = {
  page: "footer_account_management",
  key: isProd() ? "blt65c8a8a2a6d0e7a6" : "blt348cc772a200ec13",
};

export const NEW_LOGIN_FOOTER_CONTENT = {
  page: "am_login_footer",
  key: isProd() ? "blt2bcefb8727b78417" : "blt4a8ddb59690d2632",
};

export const FOOTER_HOME_CONTENT = {
  page: "am_home_footer",
  key: isProd() ? "bltda56faaf2b56a237" : "bltf251b96e027182af",
};
export const LAYOUT_CONTENT = {
  page: "layout_page",
  key: isProd() ? "bltede987a3555edef6" : "blt90940f7d449591d0",
};

export const LOGIN_FORGET_PAGE_CONTENT = {
  page: "account_management_login_forget",
  key: isProd() ? "blt7c6f4450343311cc" : "blt7007907f00fcf0da",
};

export const SIGNUP_RESET_PAGE_CONTENT = {
  page: "account_management_signup_reset",
  key: isProd() ? "bltc9de9b7208ef6247" : "blt642778d68fdbe530",
};

export const QUICK_BILL_PAYMENT_CONTENT = {
  page: "quick_bill_payments",
  key: isProd ? "bltc60712bf77a83e48" : "blt36eebdff581bac4f",
};

export const contentApiHeader = {
  api_key: !isProd() ? API_KEY : API_KEY_PROD,
  access_token: !isProd() ? ACCESS_TOKEN : ACCESS_TOKEN_PROD,
};
export const NETWORK_SETTINGS_CONTENT = {
  page: "am_network_settings",
  key: isProd() ? "blt1acd156ef18c4df7" : "blt40ee2cf6fbc2c181",
};
export const AMS_Cridentials = {
  staging: {
    amsAuthCredentitals: {
      username: "8yn5qUQyuqv98oqA5K1hgzHmttjiCGRg",
      password: "Cfir5imhfpNEdTsT",
    },
  },
  QA: {
    amsAuthCredentitals: {
      username: "NXP5dlMC6eTYM0wPGq3aJBXIuFPir28o",
      password: "6hf4l9Mw1M4H0tWC",
    },
  },
  uat: {
    amsAuthCredentitals: {
      username: "xaqrETFeDAt1KP4iyMlR5cSG7ZZtqy3e",
      password: "Dq80IzGkPBRX4QFB",
    },
  },
  production: {
    amsAuthCredentitals: {
      username: "xaqrETFeDAt1KP4iyMlR5cSG7ZZtqy3e",
      password: "Dq80IzGkPBRX4QFB",
    },
  },
};

export const watsonSearchEnviromentKeys = {
  base_url:
    "https://api.us-east.discovery.watson.cloud.ibm.com/instances/e19b336c-e6f2-4450-9afc-ed6829c84251",
  environment_id: "333e1bdb-87f4-4e80-b0e5-58b828f8f49b",
  collection_id: "3443f1b4-4bd0-405e-b098-e9ff06552b51",
  query_version: "2023-03-31",
  returnType:
    "extracted_metadata.file_type,extracted_metadata.title,metadata.source.url,text",
  tokenType: "Basic",
};

export const contentApiParams = (param) => {
  return {
    [param.page]: `entries/${param.key}${
      !isProd() ? "?environment=development" : "?environment=production"
    }`,
  };
};

export const getIAM_UserParamsByEmailAndAccountId = (email, accountId) => {
  return IAM_GET_USER_DETAILS?.replace("EMAIL", email)?.replace(
    "ACCID",
    accountId
  );
};

// in web we are using this object for setting refreshToken
export const webRefreshTokenRedux = {};

export const EC_DW_LOGGING_STEPS = {
  login_attempts: "Login Attempted",
  login_success: "Login Success",
  login_failed: "Login Failed",
  username_notfound: "Username or principalName not found",
  iam_API_failed: "IAM API failed",
  token_unavailable: "get iam token unavailable",
  reschedule_attempt: "Reschedule appointment Attempt",
  submit_reschedule: "Submit Reschedule Appointment",
  list_pay_methods: "List Payment Methods",
  change_password: "Change Password",
  account_info_update: "Account info update",
  update_pay_info: "Update Payment Info",
  set_pass_attempt: "Set Password Attempted",
  set_pass_success: "Set Password Success",
  set_pass_failed: "Set Password Failed",
  forgot_pass_attempt: "Forgot Password Attempted",
  sent_otp: "Send OTP",
  sent_otp_for_sign_up: "send-otp-sign-up",
  sent_otp_for_captcha: "send-otp-captcha",
  sent_otp_for_forget_pass: "send-otp-forget-password",
  validate_otp: "Validate OTP",
  validate_otp_for_sign_up: "Validate-otp-sign-up",
  validate_otp_for_captcha: "Validate-otp-captcha",
  validate_otp_For_forget_pass: "Validate-otp-forget-password",
  forgot_pass_success: "Forgot Password Success",
  forgot_pass_failed: "Forgot Password Failed",
  account_not_enabled: "Account Not Enabled",
  account_enabled: "Account Enabled",
  add_primary_payment: "Add Primary Payment",
  add_payment_method: "Add Payment method",
  deleted_payment_method: "Payment method deleted",
  view_statement: "view-statement-clicked",
  view_pdf: "view-pdf-clicked",
  message_tech: "Message Tech",
  add_to_calander: "Add to Calendar",
  upcomming_appointment: "Upcoming Appointment",
  invalid_upcomming_appointment_dispatch_info: "Invalid upcoming-appointment dispatch-info",
  forgot_username_attempt: "Forgot Username Attempted",
  forgot_username_success: "Forgot Username Success",
  forgot_username_failed: "Forgot Username Failed",
  create_account: "Create Account",
  create_acc_set_pass: "Create Acc Set Password",
  create_account_succcess: "Create Account Success",
  create_account_failed: "Create Account Failed",
  pause_service_date_selected: "pause_service_date_selected",
  pause_service_date_defaulted: "pause_service_date_defaulted",
  resume_service_date_selected: "resume_service_date_selected",
  pause_service_cancel_clicked: "pause_service_cancel_clicked",
  pause_service_goBack_clicked: "pause_service_goBack_clicked",
  pause_service_attempted: "pause_service_attempted",
  pause_service_success: "pause_service_success",
  pause_service_failure: "pause_service_failure",
  pause_service_clicked: "pause_service_clicked",
  pause_service_update_clicked: "pause_service_update_clicked",
  pause_service_date_defaulted: "pause_service_date_defaulted",
  resume_service_date_selected: "resume_service_date_selected",
  userNotFountWith_cust_ID_Not_Found: "User not found in C360 with customer ID",
  cust_ID_Not_Found: "CustomerID not found",
  subscription_acm_success: "Subscription-acm success",
  subscription_acm_failed: "Subscription-acm Failed",
  upcoming_payment_success: "upcoming paymnet detail success",
  upcoming_payment_failed: "upcoming paymnet detail Failed",
  invalid_upcoming_payment_due_date: "Invalid upcoming paymnet due date",
  subscription_plan_success: "Subscription-plan success",
  subscription_plan_failed: "Subscription-plan Failed",
  nutritionLabel_clicked: "nutrition label clicked",
  nutritionLabel_success: "nutrition label success",
  nutritionLabel_failed: "nutrition label failed",
  change_plan_attempted: "Change Plan Attempted",
  change_plan_falied: "Change Plan Failed",
  change_plan_success: "Change Plan Success",
  change_plan_fiber: "Change Plan Fiber Attempted",
  change_plan_voice_add: "Change Plan Add Voice Attempted",
  change_plan_voice_cancel: "Change Plan cancel Voice Attempted",
  change_plan_fiber_success: "Change Plan Fiber Success",
  change_plan_voice_add_success: "Change Plan Add Voice Success",
  change_plan_voice_cancel_success: "Change Plan cancel Voice Success",
  pay_now_attempted: "Pay Now Attempted",
  pay_now_jeopardy_attempted: "Pay Now Jeopardy Attempted",
  pay_now_success: "Pay Now Success",
  pay_now_failed: "Pay Now Failed",
  sign_in_attempt: "Sign In Attempted",
  sign_up_attempt: "Sign Up Attempted",
  sign_up_success: "Sign Up Success",
  sign_up_failed: "Sign Up Failed",
  sign_up_getAzInfo_success: "Sign Up flow getAZ API success",
  sign_up_getAzInfo_failed: "Sign Up flow getAZ API failed",
  CTL_User_With_State: "CTL loggedIn user state: <VALUE>",
  Initial_login_CTL_Welcome: "Initial CTL login Welcome Banner",
  validateUserDueToReCaptcha: "Validate user due to re-captcha",
  uni_login_address_attempt: "unilogin_attempt_address",
  uni_login_phoneNumber_attempt: "unilogin_attempt_phone",
  uni_login_address_success: "unilogin_success_address",
  uni_login_phoneNumber_success: "unilogin_success_phone",
  network_settings_page_accessed: "Network Settings Page Accessed",
  network_wifi_name_update_success: "Network WiFi Name Update Success",
  network_wifi_name_update_failed: "Network WiFi Name Update Failed",
  network_wifi_password_update_success: "Network WiFi Password Update Success",
  network_wifi_password_update_failed: "Network WiFi Password Update Failed",
  network_modem_restart_success: "Network Modem Restart Success",
  network_modem_restart_failed: "Network Modem Restart Failed",
  network_wifi_password_off_success: "Network WiFi Password turn off Success",
  network_wifi_password_off_failed: "Network WiFi Password turn off Failed",
  account_unlocked: "Account Unlocked Success",
  account_locked: "Account Locked Success",
  account_switched_success: "User switched to other account success",
  multi_account_login: "Multi accounts user has logged in",
  Add_account_attempted: "Add account attempted",
  forget_acc_num_attempted: "forget account number attempted",
  forget_acc_num_email_success: "Forget account number email success",
  forget_acc_num_email_fail: "Forget account number email failed",
  forget_acc_num_email_API_fail: "Forget account number ge by email API failed",
  forget_acc_num_email_ban_not_found: "Forget account number check with email BAN not found",
  forget_acc_num_try_again_attempt: "Forget account number Try Again attempted",
  add_acc_found_success: "Add account found successfully",
  add_acc_found_fail: "Add account could not be found",
  add_acc_OTP_success_with_email:
    "Add account OTP request sent successfully with email",
  add_acc_OTP_success_with_phone:
    "Add account OTP request sent successfully with phone",
  add_acc_OTP_resend_success:"Add account OTP resend successfully",  
  add_acc_OTP_resend_fail:"Add account resend OTP fail",
  add_acc_validated_OTP_attempted:"Add account OTP Verification code attempted",
  add_acc_validated_OTP_success:"Add account OTP Verification code Success",
  cancle_cta_add_acc_comm_not_avaliabe_page: "Cancel clicked on add account communication method not available",
  try_again_add_acc_comm_not_avaliabe_page:"Try again attempted on communication method not available",
  cancle_cta_add_acc_OTP_verification: "Cancel clicked on add account OTP verification page",
  validated_OTP_fail: "Invalid OTP entered",
  validated_OTP_success: "OTP validated successfully",
  registered_account_popup_Success: "Already registered Account popup Success",
  try_again_attempt_registered_Acc_popup:
    "Try Again attempted on registered Account popup",
  registered_account_popup_Cancle: "Cancle on registered Account popup",
  cancle_cta_add_acc_comm_avaliabe_page:"Canceled on communication method",
  add_acc_comm_not_avaliable:"Add account Communication method not available",
  add_acc_success: "Add Account successfully",
  add_acc_fail: "Add Account fail",
  add_acc_validated_OTP_fail: "Add account Invalid OTP entered",
  navigate_to_home: "Add Account success page navigate to dashboard successfully",
  cancle_cta_add_acc: "Cancel clicked on add account",
  cancle_cta_forget_acc_popup: "Cancel clicked on add account forget account popup",
  autoPay_enroll_cancle: "cancle clicked on enroll autoPay",
  autoPay_enroll_sucess: "Autopay enrolled successfully",
  autoPay_enroll_fail: "AutoPay enroll fail",
  paperless_billing_accept: "Paperless billing accepted",
  paperless_billing_decline:"Paperless billing declined",
  AP_remove_sucess: "Autopay removed successfully",
  AP_remove_goback_cta: "Remove autopay goback button clicked",
  AP_remove_confirm_cta: "Remove autopay Initiated",
  AP_remove_failed: "Autopay remove failed",
  AP_add_bank: "Autopay add a bank method link clicked",
  AP_add_CD_card: "Autopay add credit/debit card method link clicked",

  AP_add_PM_CD_card_cancel_cta: "Autopay add credit/debit card method cancel button clicked",
  AP_add_PM_CD_card_continue_cta: "Autopay add credit/debit card method continue button clicked",

  AP_add_PM_CD_card_sucess: "Autopay credit/debit card payment method add successfully",
  AP_add_PM_CD_card_failed: "Autopay add credit/debit card payment method failed",

  AP_edit_PM_CD_card_cancel_cta: "Autopay edit credit/debit card method cancel button clicked",
  AP_edit_PM_CD_card_continue_cta: "Autopay edit credit/debit card method continue button clicked",
  AP_edit_PM_CD_card_failed: "Autopay update credit/debit card payment method failed",
  AP_edit_PM_CD_card_sucess: "Autopay credit/debit card payment method updated successfully",

  AP_add_PM_bank_cancel_cta: "Autopay add bank method cancel button clicked",
  AP_add_PM_bank_continue_cta: "Autopay add bank method continue button clicked",
  AP_add_PM_bank_sucess: "Autopay bank payment method added successfully",
  AP_add_PM_bank_failed: "Autopay add bank payment method failed",

  AP_edit_PM_bank_sucess: "Autopay bank payment method updated successfully",
  AP_edit_PM_bank_failed: "Autopay update bank payment method failed",
  AP_edit_PM_bank_cancel_cta: "Autopay edit bank method cancel button clicked",
  AP_edit_PM_bank_continue_cta: "Autopay edit bank method continue button clicked",


  update_autoPay_back: "Back clicked on manage autoPay",
  
  update_PPM_confirm_back: "Back clicked on confirm primary payment method popup",
  update_PPM_confirm_continue: "Autopay change primary payment method Initiated",
  update_PPM_sucess: "Autopay primary payment method changed successfully",
  update_PPM_fail: "Autopay change primary payment method failed",
  change_PPM_cta: "Autopay change primary payment method clicked",


  CVV_validatin_fail:"CVV validation failed",
  CVV_cancle: "Cancle clicked on CVV popup",
  disable_AutoPay_cancel: "Autopay disable popup cancel button clicked",
  disable_AutoPay_sucess: "Autopay disabled successfully",
  disable_AutoPay_fail: "AutoPay disable failed",
  deleted_payment_method_cancel: "AutoPay cancle clicked on delete payment method popup",
  deleted_payment_method_sucess: "AutoPay payment method deleted successfully",
  deleted_payment_method_fail: "Autopay delete payment method failed",

  android_store_link: "Android Google Play Link Clicked",
  ios_store_link: "Apple Store Link Clicked",
  payment_successful: "step2 of 2, submit payment processed successfuly",
  payment_failed: "step2 of 2, submit payment failed",
  referalClicked: "Referral link clicked",
  register_acc_email_success:"Account register notification email sent successfully",
  
  
  agree_and_continue: "Step 1 of 2, agree and continue button is clicked",
  /** QBP steps */
  qbp_stepped_in: "qbp stepped in",
  qbp_back_step1: "qbp step-1 Back link clicked",
  qbp_cancel_step1: "qbp step-1 Cancel button clicked",
  qbp_select_bank_continue_step2: "qbp Bank selected & continue step2",
  qbp_select_credit_continue_step2: "qbp Credit selected & continue step2",

  qbp_new_bank_cta: "qbp New bank form link clicked",
  qbp_edit_bank_cta: "qbp Edit bank link clicked",
  qbp_delete_bank_cta: "qbp Delete bank button clicked",
  
  qbp_new_cc_cta: "qbp New credit form link clicked",
  qbp_edit_cc_cta: "qbp Edit credit link clicked",
  qbp_delete_cc_cta: "qbp Delete credit button clicked",

  qbp_cancel_form: 'qbp Cancel form',

  qbp_add_bank_method: 'qbp Bank method add Sucess',
  qbp_add_cc_method: 'qbp Add a credit method',
  qbp_add_bank_method_successs: 'qbp Bank method add Sucess',
  qbp_add_bank_method_failed: 'qbp Bank method add Failed',
  qbp_add_credit_method_successs: 'qbp Credit method add Sucess',
  qbp_add_credit_method_failed: 'qbp Credit method add Failed',

  qbp_update_bank_method: 'qbp Update a bank method',
  qbp_update_credit_method: 'qbp Update a credit method',
  qbp_update_bank_method_success: 'qbp Update a bank method Sucess',
  qbp_update_bank_method_failed: 'qbp Update a bank method Failed',
  qbp_update_credit_method_success: 'qbp Update a credit method Sucess',
  qbp_update_credit_method_failed: 'qbp Update a credit method Failed',

  qbp_cvv_validation_success: 'qbp cvv validation success',
  qbp_cvv_validation_failed: 'qbp cvv validation failed',
  qbp_back_step2: "qbp Step-2 Back link clicked",
  qbp_cancel_step2: "qbp step-2 Cancel button clicked",
  qbp_attempted: 'qbp payment attempted',
  qbp_success: 'Quick Bill payment completed successfully',
  // qbp_success: 'qbp payment submit success',
  qbp_failed: 'qbp payment submit failed',
  qbp_close_confirm_cta: 'qbp/unauthentic Close confirm modal',

  unauthqbp_signin_cta: 'unauthqbp AM Siginin button clicked',
  unauthqbp_stepped_in: 'unauthqbp stepped in to step-1',
  unauthqbp_back_step1: "unauthqbp step-1 Back link clicked",
  unauthqbp_cancel_step1: "unauthqbp step-1 Cancel button clicked",
  unauthqbp_actNotFound : "unauthqbp Account not found",

  unauthqbp_add_bank_continue_step2: "unauthqbp step-1 Add bank and continue",
  unauthqbp_add_cc_continue_step2: "unauthqbp step-1 Add credit and continue",
  unauthqbp_add_bank_method_success: 'unauthqbp bank method add success',
  unauthqbp_add_bank_method_failed: 'unauthqbp Bank method add Failed',
  unauthqbp_add_credit_method_successs: 'unauthqbp Credit method add Sucess',
  unauthqbp_add_credit_method_failed: 'unauthqbp Credit method add Failed',

  unauthqbp_back_step2: "unauthqbp Step-2 Back link clicked",
  unauthqbp_cancel_step2: "unauthqbp step-2 Cancel button clicked",
  unauthqbp_attempted: 'unauthqbp payment attempted',
  unauthqbp_success: 'unauthqbp payment submit success',
  unauthqbp_failed: 'unauthqbp payment submit failed',
  
  unauthqbp_bank_method_selected: 'unauthqbp bank method selected',
  update_qbp_bank_method: 'Update qbp bank method',
  unauthqbp_credit_method_selected: 'unauthqbp credit method selected',
  update_qbp_credit_method: 'Update QBP credit method',

  unauthqbp_LP_links_clicked: "unauthqpp LP links clicked",

  unauthqbp_LP_account_disconnected : "unauthqpp LP account disconnected",
  unauthqbp_LP_prepay_account:"unauthqpp LP prepay account",
  unauthqbp_LP_enterprise_account:"unauthqpp LP enterprise account",

  Pa_ineligible_landing_already_in_pa_modal:"PA ineligible due to existing payment arrangment",
  Pa_ineligible_landing_payment_issue_with_pa_modal: "PA ineligible due to payment issues",
  Pa_ineligible_landing_prepay_account_modal:"PA ineligible due to account type being a prepay account",
  Pa_ineligible_landing_enterprise_account_modal: "PA ineligible due to account type being a enterprise",
  Pa_ineligible_landing_INACTIVE_account_modal: "PA ineligible due to the user being inactive",
  Pa_back_step1: "PA step-1 Back link clicked",
  Pa_continue_step1: "PA step-1 continue cta clicked to step2",
  pa_autopay_remove_confiramtion: "PA step-1 autopay remove modal continue CTA clicked to step2",
  Pa_cancel_step1: "PA step-1 cancel cta clicked to landing page",
  pa_payment_option_select_setp1: "PA step-1 selected payment option",
  Pa_back_step2: "PA step-2 Back link clicked",
  Pa_term_and_condition_step2:"PA step-2 T&C checked",
  Pa_cancel_step2: "PA step-2 cancel cta clicked to landing page",
  Pa_agree_and_continue_step2: "PA step-2 agree and continue clicked to step3",
  Pa_add_card_payment_method_success:"PA step-2 card payment method is successfully added",
  Pa_add_card_payment_method_failure: "PA step-2 card payment method adding failed",
  Pa_edit_card_payment_method_success:"PA step-2 card payment method is successfully edited",
  Pa_edit_card_payment_method_failure: "PA step-2 card payment method editing failed",
  Pa_add_bank_payment_method_failure:"PA step-2 bank payment method adding failed",
  Pa_add_bank_payment_method_success:"PA step-2 bank payment method is successfuly added",
  Pa_edit_bank_payment_method_failure:"PA step-2 bank payment method editing failed",
  Pa_edit_bank_payment_method_success:"PA step-2 bank payment method is successfuly edited",
  Pa_past_upcoming_due_date: "PA step-2 upcoming due date is past date",
  Pa_cancel_form:"PA step-2 cancel form clicked",
  Pa_cvv_validation_success: "PA step-2 cvv validation is successful",
  Pa_cvv_validation_failure: "PA step-2 cvv validation is failed",
  Pa_unAuth_ActNotFound: "PA Account not found",
  Pa_quick_bill_on_payment_Arrangment: "PA step-3 QBP on PA success",
  Pa_quick_bill_on_payment_Arrangment_failur: "PA step-3 QBP on PA failed",
  Pa_submit_payment_arrangment: "Payment Arrangement created successfully",
  Pa_submit_payment_arrangment_failed: "PA step-3 submission failed",
  Pa_cancel_step3: "PA step-3 cancel cta clicked to landing page",
  Pa_back_step3: "PA step-3 Back link clicked",
  Pa_landing_page_links_clicked:"PA landing page links clicked",
  Pa_otp_validation_success: "PA otp validation success",
  Pa_otp_validation_failure: "PA otp validation failed",
  Pa_fetch_plan_failure:"PA fetch plan failed",
  unauth_otp_validation_success: "unauthqbp otp validation success",
  unauth_otp_validation_failure: "unauthqbp otp validation failed",
  payment_history_api_data_incorrect: "payment history api data incorrect",
  pa_date_incorrect: "pa date incorrect",
  add_AP_pay_method_bank: "The user has selected bank payment method for the autopay",
  add_AP_pay_method_CC : "The user has selected credit/debit card payment method for the autopay",
  AP_delete_PM_bank_cta: "Autopay delete bank method button clicked",
  AP_delete_PM_CD_card_cta: "Autopay delete credit/debit card method button clicked",
  AP_delete_PM_bank_cancel_cta: "Autopay delete payment method cancel button clicked",
  AP_delete_PM_confirm_cta: "Autopay delete payment method confirm button clicked",
  AP_edit_PM_bank_cta: "Autopay edit bank method button clicked",
  AP_edit_PM_CD_card_cta: "Autopay edit credit/debit card method button clicked",
  AP_enroll_cta: "Add Autopay Initiated",
  AP_enroll_failed_popup_cta: "Autopay enroll failed popup Re-enter information button clicked",
  AP_remove_cta: "Remove autopay button clicked",
  AP_remove_PPM_cta: "Autopay delete primary payment method button clicked",
  AP_remove_PPM_cancel_cta: "Autopay delete primary payment method cancel button clicked", 
  AP_remove_PPM_confirm_cta: "Autopay delete primary payment method Initiated",
  AP_remove_PPM_CVV_cancel_cta: "Autopay selected primary payment method CVV popup cancel button clicked", 
  AP_remove_PPM_CVV_confirm_cta: "Autopay selected primary payment method CVV popup confirm button clicked",
  AP_remove_PPM_sucess: "Autopay primary payment method deleted successfully",
  AP_remove_PPM_failed: "Autopay delete primary payment method failed",
  AP_disable_cta: "Autopay disable button clicked",
  AP_disable_confirm_cta: "Autopay disable Initiated",
  Pre_cvv_confirm_cta: "Prepay CVV validation Initiated",
  Pre_cvv_confirm_fail:"Prepay CVV validation failed",
  Pre_cvv_confirm_sucess:"Prepay CVV validation failed success",
  AP_cvv_confirm_cta:"Postpay CVV validation Initiated",
  AP_cvv_confirm_fail:"Postpay CVV validation failed",
  AP_cvv_confirm_sucess:"Postpay CVV validation success",
  Pre_change_PPM_onitiated: "Prepay change primary payment method Initiated",
  Pre_change_PPM_Success: "Prepay primary payment method changed successfully",
  Pre_change_PPM_Failed: "Prepay change primary payment method failed",
  pre_edit_popup_CD_PM_cta :"Prepay edit payment method open popup cta clicked",
  pre_edit_popup_CD_PM_cancle_cta :"Cancle Cta clicked from Prepay edit payment popup",
  pre_update_CD_Pay_cta:"Prepay Update credit/debit card payment method Initiated",
  pre_update_CD_Pay_fail :"Prepay update credit/debit card payment method failed",
  pre_update_CD_Pay_sucess :"Prepay credit/debit card payment method updated successfully",
  pre_add_popup_CD_PM_cta :"Prepay add payment method open popup cta clicked",
  pre_add_popup_CD_PM_cancle_cta :"Cancle Cta clicked from Prepay add payment popup",
  pre_add_CD_Pay_cta :"Prepay add credit/debit card payment method Initiated",
  pre_add_CD_Pay_fail :"Prepay add credit/debit card payment method failed",
  pre_add_CD_Pay_sucess :"Prepay credit/debit card payment method added successfully",
  pre_delete_CD_Pay_cta :"Prepay delete payment method Initiated",
  pre_delete_CD_PM_fail :"Prepay delete payment method failed",
  pre_delete_CD_PM_sucess :"Prepay payment method deleted successfully",
  pre_delete_popup_CD_Pay_cta :"Prepay delete payment method open popup cta clicked",
  pre_delete_popup_CD_Pay_cancle_cta :"Cancle Cta clicked from Prepay delete payment popup",
  add_AP_cta_clicked: "Add autopay clicked from autopay status page",
  add_acc_OTP_initiated:"Add account OTP request Initiated",
  userName_update_fail:"Update user name failed",
  userName_update_success:"Update user name success",
  add_acc_getazinfo_success:"Add acc customer details avaliabe from getazinfo response",
  add_acc_getazinfo_fail:"Add acc Customer details not avaliabe blank response from getazinfo",
  add_acc_BAN_notFound:"Add Account BAN not found in customer 360",
  customer_profile_update_attempt:"Update customer profile attempted",
  customer_profile_update_fail:"Update customer profile failed",
  customer_profile_update_success:"Update customer profile success",
  add_acc_OTP_success:"Add account OTP sent successfully",
  add_acc_OTP_fail:"Add account sent OTP failed",
  postpay_user_login_success:"Postpaid user logged in successfully",
  prepay_user_login_success:"Prepaid user logged in successfully",
  prepay_ctl_paypal_fail:"Prepay ctl add paypal payment failed",
  prepay_ctl_paypal_BA_toakn_fail:"Prepay ctl paypal BA tokan API failed",
  prepay_ctl_paypal_BA_toakn_success:"Prepay ctl paypal BA tokan API success",
  
  prepay_ctl_paypal_add_attempt:"Add paypal for ctl prepay attempted",
  prepay_ctl_paypal_add_fail:"Add paypal for ctl prepay failed",
  prepay_ctl_paypal_add_success:"Add paypal for ctl prepay successfully",
  prepay_ctl_paypal_login_cancel:"Cancle Cta clicked from PayPal login", 



  lp_unauthqbp_user_stepped: "unauthqbp user stepped in LP",
  lp_unauthqbp_migrated_user_stepped: "unauthqbp migrated user stepped in LP",
  lp_unauthqbp_nextbtn_clicked: "unauthqbp user clicked next button in LP",
  lp_pa_user_stepped: "PA user stepped in LP",
  lp_pa_migrated_user_stepped: "PA QBP migrated user stepped in LP",
  lp_pa_nextbtn_clicked: "PA QBP user clicked next button in LP",

  unauthOTP_flow_dropped: "page reloaded during unauthentic OTP flow",

  unauthOTP_PA_sendOTP_stepped: "PA send OTP stepped in",
  unauthOTP_PA_resendOTP_opted: "PA Resend OTP opted",
  unauthOTP_PA_sendOTP_submitted: "PA send OTP submitted",
  unauthOTP_PA_validateOTP_stepped: "PA validate OTP stepped in",
  unauthOTP_PA_validateOTP_submitted: "PA validate OTP submitted",

  unauthOTP_QBP_sendOTP_stepped: "unauthqbp send OTP stepped in",
  unauthOTP_QBP_resendOTP_opted: "unauthqbp Resend OTP opted",
  unauthOTP_QBP_sendOTP_submitted: "unauthqbp send OTP submitted",
  unauthOTP_QBP_validateOTP_stepped: "unauthqbp validate OTP stepped in",
  unauthOTP_QBP_validateOTP_submitted: "unauthqbp validate OTP submitted",

  user_with_1Q_offers: "user logged with 1Q offers"
};

export const notificationType = {
  creditCard: "CreditCard",
  buryCable: "BuryCable",
  acpBanner: "ACPBanner",
  paymentExpired: "suspend",
  vacationSuspend: "VacationSuspend",
  roadBore: "RoadBore",
  userSuspended: "Suspend",
  nopaymentmethod: "nopaymentmethod",
  changePlanPending: "changePlanPending",
  
};

export const companyNameofQF = [
  "QUANTUM FIBER",
  "QF",
  "QF-II",
  "QUANTUM FIBER II",
  "CENTURY LINK",
  "CTL",
];

export const CONST_VALUES = {
  account_lock: "ACCOUNT LOCKED",
  customer_id_key:
    isProd()
      ? "extension_42054e1351c040b08b185b58c192f49e_CustomerID"
      : isUAT()
      ? "extension_6645a143948b4441a17cc417cbbc0567_CustomerID"
      : "extension_a5021af605b141d78e63d3338ade2884_CustomerID",
  account_enabled_message_key: `extension_${
    isProd()
      ? `42054e1351c040b08b185b58c192f49e`
      : isUAT()
      ? "6645a143948b4441a17cc417cbbc0567"
      : `a5021af605b141d78e63d3338ade2884`
  }_AccountEnabled_Message`,
  emailRegex: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
  new_customer: "CTL",
  old_customer: "CENTURY LINK",
  lastLoginBanKey:isProd()
  ? `extension_42054e1351c040b08b185b58c192f49e_pwResetEmailId`
  : isUAT()
  ? "extension_6645a143948b4441a17cc417cbbc0567_pwResetEmailId"
  : `extension_a5021af605b141d78e63d3338ade2884_pwResetEmailId`,
};
export const publicKeySpeedPay = isProd()
? `MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAxTcACW1z9uC9En2iQNOkinSoTlw//MDLMo5eYmTza3MDYipeOXM+MKN0Ud9RkCatT5pK3Gm4OMQ18hV+m81iq+/R4gSiGfSl4mbdzZhBA6iR7/qH4sRkZvbHDmxB99fm5u2ksg2nZigW8+P+rmjDvpUqtL60DzdoMt+IFBp/e7RyPFZcahnNLSlJBU73HpYRMOKQRoaTOFLcq3rXzLM1DOwqyTTA8AFHJLJpZe+Znxj9eUj/r+f5/qgjP+28ksAsMeDNpDF3muENcVOyBbQPY+2YASwR0q1zx9EcAj75nuMHBr5P20A93Y/pxYU8fTOBeAARcA6Z/mkk/jb2fqBjPwIDAQAB`
:
`MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAvP/fwJOkay71xndL11V9ZizemWrZ4Z3YcFlNpZ5C0xu/5f/60LqpO/4916QSjsaGrzNmt/IqRwGVsBfxnYOxioa7MC3IynVjK43fE8enz0lA7F7rX5tsNbhrfNooGdhisNmMJFOWWaCt1/wJy6sHGc8mBxxnsOnW4M4kjnwSaxGkwKn53cBk3KzpsGHb8mTzpX05vYwjoSp7k6uER3HtwDfLCOxCEqjraA6vOMo8S7TNVY54iywsQkNkOymCVwOlDqVua9OUVjltNIYfIwrpsl/GMS0tm1+p3V4au3rfA9sWMfuJFyyaJ3OdvCzAwp3G2f5cH+lTXvQWC2aTV7YNjQIDAQAB`
